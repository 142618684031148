import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiServiceService } from 'src/app/api/api-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.page.html',
  styleUrls: ['./qrcode.page.scss'],
})
export class QrcodePage implements OnInit {
  version_app = environment.version;
  intervalqrcode = ""
  qrcode = {};
  constructor(
    public modalController: ModalController,
    public apiService: ApiServiceService
    ) { }

  ngOnInit() {
    this.feedPage();
    const intervalqrcode = setInterval(() => { 
      this.feedPage();
   }, 150000);

    
  }
  

  async feedPage(){
    // this.loader.simpleLoader();
    await this.apiService.gera_qr_code_inventario().subscribe((data)=>{
      this.qrcode = data;
      console.log(this.qrcode);
    });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
