import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';
import { Plugins } from '@capacitor/core';


const TOKEN_KEY = 'my-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  infos = [];
  APIurl = environment.apiUrl;
  base_url = environment.linkSite;
  Storage = Storage;

  constructor(private http: HttpClient) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: {email, password}): Observable<any> {
    return this.http.post(this.APIurl+'login', credentials).pipe(

      map((data: any) => data),
      switchMap(token => {
        return from(Storage.set({key: 'usuario_logado', value: JSON.stringify(token)}))
      }),
      tap(_ => {
        Storage.set({key: TOKEN_KEY, value: 'logado'});
        this.isAuthenticated.next(true);
      })
    )
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    Storage.remove({key: TOKEN_KEY});
    return Storage.remove({key: 'usuario_logado'});
  }

  public getStoredData() {
    return Storage.get({key : 'usuario_logado'}).then((val) => {
             return val;
     });
    }


  async getUserData():Promise<any> {

    let retorno = {};
    await Storage
      .get({
        key: 'usuario_logado'
      })
      .then(userObj => retorno = JSON.parse(userObj.value))
      .catch(err => {
        // console.log(err)
        // return null;
      });

      return retorno;

  }

}
