import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HttpClientModule } from '@angular/common/http';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { CameraPreview, CameraPreviewPictureOptions, CameraPreviewOptions, CameraPreviewDimensions } from '@ionic-native/camera-preview/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InfoEscalaPage } from './modal/info-escala/info-escala.page';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import '@capacitor-community/camera-preview';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'
import { HeaderComponent } from './header/header.component';
import { GoogleMapComponent } from './google-map/google-map.component';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [ IonicModule.forRoot(), AppRoutingModule,HttpClientModule,Ng2SearchPipeModule,BrowserModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [
    Camera,
    AppAvailability,
    AndroidPermissions,
    Geolocation,
    CameraPreview,
    BarcodeScanner,
    InAppBrowser,
    File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    ],

  bootstrap: [AppComponent],
})
export class AppModule {}
