import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  base_url = environment.linkSite;
  base_path = environment.apiUrl;
  data_user:any = {};

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    ) {
      this.auth.getUserData().then(data => this.data_user = data);

    }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Version-API': environment.version
    })
  }
  httpOptions_file = {
    headers: new HttpHeaders({
      // 'Content-Type' : 'multipart/form-data',
      // 'Content-Type': 'multipart/form-data',
      'Accept':'application/json',
    })
  }

  getComunicados(tipo) {
    return this.http.post(this.base_path+"comunicados",{tipo:tipo},this.httpOptions);
  }

  getMessages() {

    return this.http.post(this.base_path+"mensagens",{cpf: this.data_user.cpf},this.httpOptions);
  }
  get_inventario() {
    return this.http.post(this.base_path+"inventario_do_lider",{cpf: this.data_user.cpf},this.httpOptions);
  }
  escala_no_app() {
    return this.http.post(this.base_path+"escala-no-app",{cpf: this.data_user.cpf},this.httpOptions);
  }
  cadastra_escala(dados_escala,tipo) {
    return this.http.post(this.base_path+"app-cadastra-escala",{info_escala: dados_escala, action: tipo},this.httpOptions);
  }

  listcolaborador_ativo() {
    console.log("Api",this.data_user);
    return this.http.post(this.base_path+"list-colaborador-ativo",{cpf: "Felinha.", fela: this.data_user.cpf},this.httpOptions);
  }

  getEscala_inventario(credencial) {
    return this.http.post(this.base_path+"getEscala_inventario",credencial,this.httpOptions);
  }
  bater_ponto(base64image) {
    //console.log('base64',base64image);
    return this.http.post(this.base_path+"bater_ponto",{base64image:base64image},this.httpOptions);
  }

  valida_qrcode(qrcode) {
    return this.http.post(this.base_path+"valida_qrcode",{qrcode:qrcode},this.httpOptions);
  }

  valida_reconhecimento(dados_inventario) {
    return this.http.post(this.base_path+"valida_reconhecimento_novo",dados_inventario,this.httpOptions);
  }
  ref_meses_ponto() {
    return this.http.get(this.base_path+"meses-folhas",this.httpOptions);
  }
  lista_contas_contabeis() {
    return this.http.get(this.base_path+"list-contas-contabeis",this.httpOptions);
  }
  minhas_transferencias_ativos() {
    return this.http.post(this.base_path+"minhas-transferencias-ativos",{cpf: this.data_user.cpf },this.httpOptions);
  }
  
  espelho_ponto(mes_ref:string) {
    return this.http.post(this.base_path+"espelho_ponto_mes",{mes_ref: mes_ref, cpf: this.data_user.cpf},this.httpOptions);
  }
  prestacao_contas() {
    return this.http.post(this.base_path+"minhas-prestacoes",{cpf: this.data_user.cpf},this.httpOptions);
  }
  get_ponto_escritorio() {
    return this.http.post(this.base_path+"get-ponto-escritorio",{cpf: this.data_user.cpf},this.httpOptions);
  }
  get_cfg_recfacial() {
    return this.http.post(this.base_path+"get-config-expressoes",{},this.httpOptions);
  }
  gera_qr_code_inventario() {
    return this.http.get(this.base_path+"gera-qr-code-escala",this.httpOptions);
  }

  cadastra_contas(formulario:FormData){
    formulario.append("cpf_colaborador", this.data_user.cpf);
    return this.http.post(this.base_path+"minhas-prestacoes/create",formulario,this.httpOptions_file);
  }
  transf_ativo(doc_para:string,ativo:string) {
    return this.http.post(this.base_path+"cadastra-ativo-transferencia",{doc_de: this.data_user.cpf, doc_para: doc_para, ativo: ativo},this.httpOptions);
  }
  receber_ativo(ativo:string) {
    return this.http.post(this.base_path+"receber-ativo-transferencia",{doc_recebimento: this.data_user.cpf, ativo: ativo},this.httpOptions);
  }

  altera_senhaperfil(password:string,repassword:string,idusuario){
    this.auth.getUserData().then(data => this.data_user = data);
    
    return this.http.post(this.base_path+"altera-senha-colaborador",
              {idusuario: idusuario,
                password: password,
                repassword:repassword}
            ,this.httpOptions);
  }

}
