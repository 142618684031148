import { Component, OnInit, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { environment } from 'src/environments/environment';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { QrcodePage } from './modal/qrcode/qrcode.page';
import { Storage } from '@capacitor/storage';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { ApiServiceService} from 'src/app/api/api-service.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  titleActive: String = "Notícias";
  intervalNotifications;
  networkStatus: any;
  networkListener: PluginListenerHandle;
  hasConnection:Boolean = false;
  version_app = environment.version;
  urlSite = environment.linkAsset;
  data_user:any = {} = [{nome: "Indefinido", libera_qrcode: "N"}];
  userlogado:Boolean =false;
  navigate : any;
  block_access= false;
  constructor(
    private auth: AuthenticationService,
    private appAvailability: AppAvailability,
    private platform: Platform,
    private _router : Router,
    public modalController: ModalController,
    public apiService: ApiServiceService,
    private location: Location
    )
  {

    
    this.auth.getUserData().then(data => this.data_user = data);
    this.checkConnectionAPP();
    this.initializeApp();
    console.log("InitializaAPP");
  }

  goBack() {
    
    this.location.back();
    }

  changeTitle(title){
    
    this.titleActive = title;
  }
  checkmalicious(){

    let app;

    if (this.platform.is('ios')) {
      app = 'twitter://';
    } else if (this.platform.is('android')) {
      app = 'com.lexa.fakegps';
    }

    this.appAvailability.check(app)
      .then(
        (yes: boolean) => this.block_access = true,
        (no: boolean) => this.block_access = false
      );
  }

  checkConnectionAPP(){
      this.networkListener = Network.addListener('networkStatusChange', (status) => {
        this.hasConnection = status.connected;
        // console.log('Fela?',status.connected);
      });
      // console.log("Modificou e chamou o getnetworkstatus");
      this.getNetWorkStatus();
  }

  async getNetWorkStatus() {
    // console.log("Chamou!!!");
    this.networkStatus = await Network.getStatus();
    this.hasConnection = this.networkStatus.connected;
    // console.log('Changed',this.networkStatus);
  }

  endNetworkListener() {
    if (this.networkListener) {
      this.networkListener.remove();
    }
  }

  ngOnDestroy() {
    if (this.networkListener) {
      this.networkListener.remove();
    }
  }

  async initializeApp() {

  }

  async ngOnInit() {
    // this.random();

    this.data_user = await this.auth.getUserData();
    if(this.data_user){
      this.userlogado = true;
      this.sideMenu();
    } else {
      this.data_user = {nome: "Indefinido", libera_qrcode: "N"};
    }

    setInterval(() => { this.checkmalicious() }, 1000 * 10)


  }

  async inicializaNotificao(){
    this.apiService.getMessages().subscribe(response => {})
    // console.log(this.apiService.getMessages());
  }
async sideMenu()
  {
    this.inicializaNotificao();
    // Ok, vamos criar função para buscar a cada 1 segundo informações.
    this.intervalNotifications = setInterval(async () => { this.inicializaNotificao() }, 15000)

    const qrcodeliberado = (this.data_user.libera_qrcode ==  "SS") ? true : false;
    const nomeponto = this.data_user.nome_ponto;
    const ponto_escritorio = this.data_user.ponto_escritorio;
    this.data_user = this.auth.getUserData().then(data => this.data_user = data);

    this.navigate = []
    this.navigate.push({icon: 'cog', title: 'Alterar Senha', url: 'change-password'});
    this.navigate.push({icon: 'home-outline', title: 'Feed de notícias', url: 'welcome'});
    this.navigate.push({icon: 'barcode', title: 'Ativos', url: 'ativos'});
    this.navigate.push({icon: 'calendar', title: 'Escala', url: 'calendario'});
    if(ponto_escritorio === true){
      this.navigate.push({icon: 'finger-print', title: 'Ponto Escritório', url: 'ponto-escritorio'});
    }  
    if(qrcodeliberado === true){
      this.navigate.push({icon: 'flashlight', title: 'QRCODE inventário', url: 'welcome', modal: true});
    }
    this.navigate.push({icon: 'barcode-outline', title: nomeponto, url: 'inventarios'});
    this.navigate.push({icon: 'person-add-outline', title: 'Espelho de ponto', url: 'espelho-de-ponto'});
    this.navigate.push({icon: 'cash-outline', title: 'Financeiro', url: 'financeiro'});
    this.navigate.push({icon: 'chatbox-outline', title: 'Mensagens', url: 'mensagens'});
    this.navigate.push({icon: 'exit', title: 'Logout', url: 'logout'});

  }

  async openModal(variable:string){

    const modal = await this.modalController.create({
      component: QrcodePage,
      cssClass: 'modal-fullscreen',
      componentProps: { 'item' : variable }
    });
    return await modal.present();
  }

}
